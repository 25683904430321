html, body {
	width: 100%;
	height: 100%;
}

legend {
    font-size: 1em;
    float: none;
    width: auto;
}

#root {
	width: 100%;
	height: 100%;
}

.rounded-3vmin {
    border-radius: 3vmin!important;
}

.rounded-5vmin {
    border-radius: 5vmin!important;
}

.rounded-top-3vmin {
    border-top-left-radius: 3vmin!important;
    border-top-right-radius: 3vmin!important;
}

.rounded-top-5vmin {
    border-top-left-radius: 5vmin!important;
    border-top-right-radius: 5vmin!important;
}

.rounded-bottom-3vmin {
    border-bottom-left-radius: 3vmin!important;
    border-bottom-right-radius: 3vmin!important;
}

.rounded-bottom-5vmin {
    border-bottom-left-radius: 5vmin!important;
    border-bottom-right-radius: 5vmin!important;
}

.letter-block {
    width: 20vmin;
    height: 20vmin;
    border-radius: 5vmin!important;
}

.letter-block.disabled {
    background-color: var(--bs-primary) !important;
    color: var(--bs-primary-text) !important;
}

#word {
    transition : background-color 500ms ease;
}

#word.success {
    background-color: var(--bs-success) !important;
    color: var(--bs-success-text) !important;
}

#word.error {
    background-color: var(--bs-danger) !important;
    color: var(--bs-danger-text) !important;
}

.word-block {
    width: 12vmin;
    height: 12vmin;
    border-radius: 3vmin!important; 
    transition : border-color 500ms ease;
}

.scoreboard-header {
    text-align: center;
    background-color: var(--bs-primary);
    color: var(--bs-primary-text);
}

.scoreboard-body {
    background-color: var(--bs-light);
    color: var(--bs-light-text);
}

.toast-container {
    width: 25%;
}

.playbtn {
    border-radius: 5vmin!important;
}

@media only screen and (min-width: 768px){

.toast-container {
    width: auto !important;
}

.playbtn {
    border-radius: 3vmin!important;
}

}